import { Card, CardMedia } from "@mui/material";
import { YouTubeEmbed } from "react-social-media-embed";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import update from "../pictures/Update.png";

const BigScreenEvents = (props) => {
  return (
    <Grid container xs={12} mt={5} spacing={1} justifyContent="center" alignContent="center">
      
      <Grid item xs={1} />
      <Grid item xs>
          
        <Card sx={{ borderRadius: "30px" }}>
        <a
                href={props.instagramFasa}
                target="_blank"
                rel="noopener noreferrer"
              >
          <CardMedia
            component="img"
            image={update}
            alt="Image"
            sx={{ maxWidth: 500 }}
          />
           </a>
        </Card>
      </Grid>

      <Grid item xs={4}>
        <Card sx={{ borderRadius: "30px", aspectRatio: 3/2 }}>
          
            <YouTubeEmbed
              url={props.youtube}
              height={460}
              width="100%"
              align="center"
            />
        </Card>
      </Grid>

      <Grid item xs>
      <iframe 
          title="FASA Calendar"
          src="https://calendar.google.com/calendar/embed?src=1877bb370f6168ea95aa8a4d1fe873d435066c4cf05dee42316b9c95c8deaec0%40group.calendar.google.com&ctz=America%2FNew_York" 
          style={{ border: 0 }}
          width="500" 
          height="400" 
          frameborder="0" 
          scrolling="no"/>
      </Grid>

      <Grid item xs={1} />
    </Grid>
  );
};

export default BigScreenEvents;
